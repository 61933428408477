/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";  
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

body{
    font-family: "Helvetica LT Std Black", Arial, sans-serif !important;
}

body h1,h2,h3,h4,h5{
    font-family: "Helvetica LT Std Black", Arial, sans-serif !important;
}

body p{
    font-family: "Helvetica LT Std", Arial, sans-serif;
}


//------------------------------------------INICIO DE PAGINAS global--------------------------------------------------
.jumbotron {
    padding: 3rem 2rem;
    padding-top: 2rem;
    background: linear-gradient(
        #002A55,
        rgba(40, 120, 235, 0.9)),
    url(../src/app/documentacion/img/header_index.jpg),
    no-repeat center center;
    background-size: cover;
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
    text-align: center;
    position: relative !important;
    margin-bottom: 0px !important;
}

.bordeSup{
    bottom: 0;
    background: url(../src/app/documentacion/img/bordeInferior2.png) bottom center no-repeat;
    background-size: cover;
}

.bordeSup2{
  bottom: 0;
  background: url(../src/app/documentacion/img/bordeInferior2C.png) bottom center no-repeat;
  background-size: cover;
}

.bordeSup3{
  bottom: 0;
  background: url(../src/app/documentacion/img/bordeInferior2D.png) bottom center no-repeat;
  background-size: cover;
}

.bordeSupDistancia{
    position: absolute;
    content: "";
    width: 100%;
    height: 85px;
    left: 0;
    z-index: 1;
}

.btn-superior{
    //width: 100%;
    float: none;
    margin: 10px;
    text-align: center;
    display: flex;
    color: #ffffff !important;
    border: 2px solid #ffffff;
    border-radius: 15px;
}

.btn-superiore{
  //width: 100%;
  float: none;
  margin: 10px;
  text-align: center;
  display: flex;
  color: #ffffff !important;
  border: 2px solid #ffffff;
  background-color: #002A55;
}
.btn-superiore-home{
  //width: 100%;
  float: none;
  margin: 10px;
  text-align: center;
  display: flex;
  
  color: #ffffff !important;
  border: 2px solid #ffffff;
  border-radius: 15px;

}
.Menu-botones-home{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.btn-superior a{
    float: none;
    margin-left: 0px; /* Ajusta el espacio entre botones si es necesario */
    text-align: center;
    color: #ffffff !important;
    text-decoration: none !important;
    font-size: 15px;
}

.btn-cursos{
    color: #fff !important;
    text-decoration: none;
    background-color: #4080C2 solid !important;
}

//---------------------------------------------------------------------------------------------------------------------------

.container {
    min-height: 100vh; /* Establecer una altura mínima igual a la altura de la ventana */
    padding-bottom: 50px; /* Ajustar el valor según la altura del div del footer */
}

.baseTexto{
    border: 30px solid #4080C2;
    background-color: #4080C2;
    border-top: 2rem;
    color: white;
    position: absolute;
    width: 100%;
    padding: 10px 0;
}

.contenido {
    max-width: 800px;
    padding: 20px;
}

.two{
    animation-name: logo;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: 3s;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

@keyframes logo {
    0%,
    100% {
      transform: scale(0.9);
    }
    25%,
    75% {
      transform: scale(0.9), rotateY(90);
    }
    50% {
      transform: scale(1.5);
    }
  }





.one:hover {
    animation-name: bounce;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: 3s;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }
  
  @keyframes bounce {
    0%,
    100% {
      transform: scale(1);
    }
    25%,
    75% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1.1);
    }
  }

  ion-button:hover {
    //background: #002A55;
    background: #51338B;
  }

.two{
    animation-name: logo;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: 3s;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

@keyframes logo {
    0%,
    100% {
      transform: scale(1);
    }
    25%,
    75% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1.5);
    }
  }

ion-accordion {
    margin: 0 auto;
}

ion-accordion.accordion-expanding,
ion-accordion.accordion-expanded {
    width: calc(100% - 32px);
    margin: 16px auto;
}

ion-accordion.accordion-collapsing ion-item[slot='header'],
ion-accordion.accordion-collapsed ion-item[slot='header'] {
  --background: #4080C2;
  --color: var(--ion-color-light-contrast);
  font-size: 17px;
}

ion-accordion.accordion-expanding ion-item[slot='header'],
ion-accordion.accordion-expanded ion-item[slot='header'] {
  --background: #4080C2;
  --color: var(--ion-color-primary-contrast);
}



//------------------------------------------------nosotros------------------------------------------------
.imgUno{
  background: url(../src/app/documentacion/img/quienesSomos.jpg);
  width: 30%;
  height: 400px;
  background-size: cover;
}

.textUno{
  color: #4080C2;
  width: 70%;
  border: 30px solid #ffffff29;
  border-top: 30px solid #fff !important;
  font-size: 30px !important;
}

.card-face {
  background-color: #1e1e1ec6;
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden; /* Oculta la cara trasera al principio */
  transition: transform 0.5s; /* Animación de volteo */
}

.front {
  transform: rotateY(0deg); /* Inicialmente visible (cara frontal) */
}

.back {
  transform: rotateY(180deg); /* Inicialmente oculta (cara trasera) */
}

.card:hover .front {
  transform: rotateY(-180deg); /* Al pasar el mouse, voltea hacia atrás */
}

.card:hover .back {
  transform: rotateY(0deg); /* Al pasar el mouse, voltea hacia adelante */
}

.card-inf-nos{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: round;
}

.banerSup{
  background: url(../src/app/documentacion/img/Banner-superior.jpg);
  background-size: contain;
  width: 100%;
  background-repeat: no-repeat;
  // height: 250px;
  height: 30vh;
}

.conMisVis{
  width: 50%;
  border: 1rem solid #ffffff23;
  border-bottom: 1rem solid #fff !important;
  border-radius: 30px;
}

.misVis{ //se repite en mas de una parte
  color: #4080C2;
  display: flex;
  text-align: center;
  font-size: 20px !important;
  background: transparent;
  background-size: cover;
  flex-wrap: wrap;
}

.gerenteInfo{
  border: 20px solid transparent;
  width: 60%;
  color: #4080C2;
  font-size: 20px !important;
}

.gerenteImg{
  background: url(../src/app/documentacion/img/gerente.jpg);
  background-size: contain;
  border: 10px solid #4080C2;
  border-radius: 25%;
  border-style: outset;
  width: 400px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 3%;
}

//------------------------------------------------area academica------------------------------------------------
.arAc {
  color: #fff;
}

.oculto {
  display: none;
}

.arAc ion-item{
  padding-left: 10px;
  padding-right: 10px;
  border: 3px solid #fff;
  border-radius: 25px;
  margin: 10px;
}

.oneVis{
  animation: bounce 5s infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: 3s;
  animation-fill-mode: forwards;
  animation-play-state: initial;
}

.logoInf{
  border: 10px solid #4080C2;
  font-size: 3em;
  justify-content: flex-start !important;
}

//------------------------------------------------Cursos------------------------------------------------
.filter{
  //background-color: #002A55;
  background-color: #4080c2;
  color: #fff;
  border-radius: 50px;
}

.filCon{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.textoA{
  width: 100%;
  text-align: center;
}

//------------------------------------------------info curso------------------------------------------------
.cont{
  display: flex;
  justify-content: center;
}

.imga{
  //width: 50%;
  //max-width: 60%;
  margin: 25px;
}

.imga img{
  border-radius: 40px;
}

.costo{
  border-radius: 40px;
  background-color: #002A55;
  color: #fff;
  text-align: center;
  width: 36%;
  margin: 25px;
  //height: 250px;
  padding: 25px;
  font-size: 18px;
}

.info-docente{
  background-color: #002A55;
  color: #fff;
  text-align: center;
}
.info-docente h1{
  text-align: center;
  font-size: 30px;
}

//------------------------------------------------info docentes------------------------------------------------
.for-img{
  width: 70%;
}

.for-img-a{
  width: 30%;
  margin: 30px;
  //border: 10px solid #002A55;
}

.for-img-a img{
  border-radius: 40px;
}

.for{
  display: flex;
  justify-content: center;
}

// .inf-cont{
//   text-align: left;
// }

//------------------------------------------------home------------------------------------------------
//------------------------------------------------barras superiores------------------------------------------------
.asdf{
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
}

.btn{
  background: transparent !important;
  width: 100%;
}
//=====================================Primer Contenido=====================================
.bordeA1{
  border: 50px solid #ffffff00;
  font-size: 50px;
  display: flex !important;
  border-top: 50px solid #fff;
  justify-content: space-around;
}

.bordeA2{
  border: 30px solid #ffffff00;
}

// .img-info{
//   background: url(../src/app/documentacion/img/online_index-2.webp);
//   background-size: cover;
//   border-radius: 40px;
// }

.img-info{
  background: url(../src/assets/imgPagina/index1.png);
  background-size: cover;
  border-radius: 40px;
}

// .cort-mit-a-img{
//   width: 40%; /* Ajusta el ancho del div según tus necesidades */
//   height: 550px; /* Ajusta la altura de los divs según tus necesidades */
// }

.cort-mit-a-img{
  //width: 100%; /* Ajusta el ancho del div según tus necesidades */
  width: 50%;
  height: 750px; /* Ajusta la altura de los divs según tus necesidades */

}

.cort-mit-inf{
  width: 48%;
  height: 600px; /* Ajusta la altura de los divs según tus necesidades */
  font-size: 30px;
}

.cort-mit-inf h1{
  font-size: 30px;
}

.info-cuadro{
  display: flex;
  flex-wrap: wrap; /* Permite que los divs se envuelvan en la siguiente línea si no caben en una fila */
  justify-content: center;
}

.cursoOnline{
  background-color: #002A55;
  border: 10px solid #002a55;
  border-radius: 25px;
  font-size: 20px;
  margin: 10px;
}

.relatores{
  background-color: #00468d;
  border: 10px solid #00478d;
  border-radius: 25px;
  font-size: 20px;
  margin: 10px;
}

.estudiantes{
  background-color: #0067cd;
  border: 10px solid #0067cd;
  border-radius: 25px;
  font-size: 20px;
  margin: 10px;
}

.cuad{
  color: #fff;
  width: 150px;
  text-align: center;
}

//=====================================Segundo Contenido=====================================
.segVista{
  background: transparent;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  margin: 30px;
}

.borde{
  border: 10px solid #ffffff25;
  border-bottom: none;
  width: 50%;
}

.contLogos{
  display: flex;
  border: 10px solid #ffffff27;
  justify-content: center;
  align-items: center;
}

.logoAzul{
  background: url(../src/app/documentacion/img/logoAzul.png);
}

.logoRojo{
  background: url(../src/app/documentacion/img/logoRojo.png);
}

.logoAmarillo{
  background: url(../src/app/documentacion/img/logoAmarillo.png);
}

.log{
  width: 150px !important;
  height: 150px !important;
  width: 25%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
}

.textLogo{
  width: 80%;
  margin: 10px;
}

.imgSeg{
  background: url(../src/app/documentacion/img/alumno1.jpeg);
  border-radius: 40px;
  height: 600px;
  width: 50%;
  object-fit: cover;
  align-items: center;
}











.container {
  flex-direction: column;
}

.curRel{
  width: 200px;
}

.az{
  border-radius: 25px;
  background-color: #4080C2 !important;
}

.az2{
  position: absolute;
  display: contents;
  float: none;
  font-size: 14px;
  text-align: center;
}

.azx{
  background-color: #4080C2;
}
.cort-mit p{
  color: #002A55;
  text-align: left;
  margin-left: 1rem;
  font-size: 20px;
}

.imgColaboradores{
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.imgColaborador{
  border: 10px solid #ffffff18;
}

//------------------------------------------contacto------------------------------
.pregunta{
  height: 500px;
  background: url(../src/app/documentacion/img/pregunta.jpg);
  background-size: cover; /* Esta propiedad ajusta la imagen al tamaño del contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

.trabajo{
  height: 700px;
  background: url(../src/app/documentacion/img/trabajo.jpg);
  background-size: cover; /* Esta propiedad ajusta la imagen al tamaño del contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

.contacto{
  background-color: #002A55;
 
  border: 10px solid #002A55;
  display: flex;
  align-items: center;
}

.dudas{
  background-color: #002A55;
  color: white;
  width: 50%;
  text-align: center;
}

.logosInferiores{
  display: flex;
  width: 100%;
  height: 50px;
}

@media screen and (max-width: 2050px) {

  .imga{
    width: 45%;
  }

}

@media screen and (max-width: 1700px) {

  .cort-mit-a-img{
    //width: 100%; /* Ajusta el ancho del div según tus necesidades */
    width: 50%;
    height: 620px;
  }

}


@media screen and (max-width: 1540px) {

  
  .cort-mit-a-img{
    // width: 100%; /* Ajusta el ancho del div según tus necesidades */
    // height: 620px;

    // width: 37%;
    // height: 738px;

    width: 59%;
    height: 620px;
  }

  .img-info{
    background: url(../src/assets/imgPagina/index1.png);
    background-size: cover;
    border-radius: 40px;
  }


}


@media screen and (max-width: 1050px) {

  .trabajo{
    height: 400px;
  }

  .pregunta{
    height: 400px;
  }

  .cort-mit-a-img{
      width: 100%; /* Ajusta el ancho del div según tus necesidades */
      height: 650px;
  }

  .cort-mit-inf{
      width: 100%;
  }

  .bordeA1{
    display: block !important;
  }

  .borde{
    width: 100%;
  }

  .imgSeg{
    width: 100%;
  }

  .segVista{
    display: block;
    font-size: 25px;
    margin: 10px;
  }

  .gerenteInfo{
    border: 20px solid #ffffff21 ;
    width: 60%;
    color: #4080C2;
    font-size: 20px !important;
  }


  
}


@media screen and (max-width: 1000px) {

  .img-info{
    background: url(../src/assets/imgPagina/index1.png);
    background-size: cover;
    border-radius: 40px;
  }

  .cort-mit-a-img{
    width: 50%; /* Ajusta el ancho del div según tus necesidades */
    height: 695px; /* Ajusta la altura de los divs según tus necesidades */
    margin: auto;
  }


  .bordeA1{
    border: 0px solid #ffffff00;
    border-top: 50px solid #fff;
  }

}

@media screen and (max-width: 850px) {
//@media screen and (max-width: 1000px) {

  .for-img{
    width: 50%;
  }
  
  .for-img-a{
    width: 50%;
  }

  .trabajo{
    background: transparent;
    height: 0px;
  }
}

@media screen and (max-width: 790px) {

  .contacto{
    flex-direction: column;
  }

  .pregunta{
    height: 500px;
  }

  .dudas{
    width: 90%;
    border: 10px solid #002a5510;
  }

  .imgUno{
    background: none;
    width: 0%;
    height: 0px;
  }

  .textUno{
    color: #4080C2;
    width: 100%;
    border: 10px solid #ffffff35;
    font-size: 30px !important;
  }

  .textUno p{
    font-size: 20px;
  }

  .banerSup{
    background: url(../src/app/documentacion/img/Banner-superior.jpg);
    background-size: contain;
    width: 100%;
    background-repeat: no-repeat;
    height: 100px;
  }

  .misVis{ //se repite en mas de una parte
    color: #4080C2;
    display: flex;
    text-align: center;
    font-size: 20px !important;
    background: transparent;
    background-size: cover;
    flex-wrap: wrap;
  }

  .conMisVis{
    width: 100%;
    border: 1rem solid #fff;
  }

  .gerenteInfo{
    border: 20px solid #ffffff11;
    width: 50%;
    color: #4080C2;
    font-size: 20px !important;
  }
}

//@media screen and (max-width: 750px) {
@media screen and (max-width: 1040px) {
  .cont{
    display: flex;
    flex-wrap: wrap;
  }
  
  .imga{
    width: 100%;
  }
  
  .costo{
    width: 100%;
  }

  .asdf{
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 720px) {
  .cort-mit-a-img{
    height: 600px; /* Ajusta la altura de los divs según tus necesidades */
    width: 100%;
  }

  .bordeA1{
    height: 1300px;
  }

  .contLogos{
    font-size: 15px;
  }
}

@media screen and (max-width: 670px) {
//@media screen and (max-width: 1000px) {

  .bordeA1{
    height: 1300px;
  }

  .info-docente h1{
    text-align: center;
    font-size: 30px;
  }

  .info-docente h2{
    font-size: 20px;
  }

  .for{
    display: flex;
    flex-wrap: wrap;
  }

  .for-img{
    width: 100%;
  }
  
  .for-img-a{
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .gerenteInfo{
    width: 100%;
    text-align: left;
  }
}

@media screen and (max-width: 580px) {
  .bordeA1{
    height: 1400px;
  }

  .contacto{
    border: 0px;
  }

  .pregunta{
    height: 400px;
  }
}

@media screen and (max-width: 520px) {
  .bordeA1{
    height: 1450px ;
  }

  .contLogos{
    display: flex;
    flex-direction: column;
  }

  .gerenteImg{
    border: 10px solid #4081c2;
    border-style: outset;
    margin-top: 20px;
  }
}

@media screen and (max-width: 500px) {
  .bordeA1{
    height: 1300px;
    font-size: 15px !important;
  }

  .cort-mit-inf{
    font-size: 15px;
  }

  .pregunta{
    height: 300px;
  }
}

@media screen and (max-width: 380px) {

  .bordeA1{
    border: none;
  }

  .imgSeg{
    background: none;
    height: 0px;
  }

  .info-docente h2{
    font-size: 15px;
  }

  .pregunta{
    background: transparent;
    height: 0px;
  }
}

@media screen and (max-width: 300px) {
  .borde p{
    font-size: 15px;
  }

  .gerenteImg{
    width: 200px;
    height: 150px;
  }

  .inf-cont{
    text-align: center;
  }
}

@media screen and (max-width: 240px) {
  .bordeA1{
    height: 1450px;
  }
}

.filtro{
  width: 50%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 25%;
}

.filtro-cont{
  background-color: #4080C2;
  color: #002A55;
  text-align: center;
}

.filtro-inf{
  width: 70%;
  margin-left: 15%;
}

.filtro-btn{
  width: 50%;
  margin-left: 25%;
}

.btn-cursos{
  //background-color: #002A55;
  color: #4080C2;
  text-align: center;
  width: 100%;
  border-left: 15%;
  border-right: 15%;
}

.btn-curso{
  background-color: #4080C2;
  color: #ffffff;
  text-align: center;
  width: 100%;
  border-left: 15%;
  border-right: 15%;
  text-decoration: none;
}

.siguiente{
  text-align: center;
  color: #002A55;
  width: 20%;
  margin-left: 40%;
}

.insis{
  color: #fff;
  text-decoration: none;
}